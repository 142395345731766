<template>
  <v-container class="custom-container">

    <v-row class="row-container">
      <v-col cols="auto" class="align-center text-end">
        <slot name="actions">

          <UploadButton
            v-if="showUploadButton && canCreateEntries"
            :availableActions="availableActions"
            @file-upload="openUploadModal"
            @create-folder="openFolderModal"
            @create-google-doc="openGdocModal"
            @create-synthetic-media="openSyntheticMediaModal"
            @create-self-service-template="openSelfServiceTemplateModal"
          />

          <v-btn
            v-for="(action, index) in actions"
            :key="index"
            :color="action.color ? action.color : 'primary'"
            @click="$emit(action.eventToEmit)"
          >
            {{ $t(action.text) }}
          </v-btn>
        </slot>
      </v-col>

      <v-spacer></v-spacer>

      <v-col v-if="showToggleView" cols="auto" class="align-center text-end">
        <v-btn-toggle
          :value="contentPersonalPreferences.navigationViewMode"
          @change="viewModeChanged"
          color="primary"
          active-class="primary--text"
          mandatory
          dense
        >
          <v-btn value="grid">
            <v-icon color="defaultIconColor">mdi-view-grid-outline</v-icon>
          </v-btn>
          <v-btn value="list">
            <v-icon color="defaultIconColor">mdi-view-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row class="row-container align-end align-md-center flex-column-reverse flex-md-row py-3 mb-1">
      <v-col
        cols="12"
        lg="8"
        md="9"
      >
      <Breadcrumbs :items="breadcrumbsItems" :searchResult="searchResult" maxWidthItem="25%"/>
        <slot name="content-actions"></slot>
      </v-col>

      <v-col
        v-if="showSearchBar"
        cols="12"
        sm="6"
        lg="4"
        md="3"
      >
        <v-menu
          offset-y
          nudge-bottom="8"
          :close-on-content-click="false"
          max-width="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              v-model="searchTerm"
              :label="$t('search')"
              @keydown.enter="searchValueChanged"
              clearable
              append-icon="mdi-magnify"
              @click:append="searchValueChanged"
              @click:clear="clearSearch"
              hide-details
              class="mt-0 pt-1"
            >
            </v-text-field>
          </template>

          <v-card>
            <v-card-title>{{ $t('search_in') }}:</v-card-title>

            <v-card-text class="text-center">
              <v-btn-toggle
                v-model="searchPlace"
                color="primary"
                class="vertical-btn-group"
                mandatory
                borderless
                dense
              >
                <v-btn
                  block
                  :value="currentFolderId"
                >
                  {{ $t('this_folder') }}
                </v-btn>

                <v-btn
                  block
                  :value="currentRepositoryId"
                >
                  {{ $t('this_repository') }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
            <v-card-actions >
              <v-spacer></v-spacer>

              <v-btn
                @click="searchValueChanged"
                color="primary"
                text
              >
                {{ $t('search') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <v-container
      @drop.prevent="onDrop"
      @dragover="dragOver"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @dragend="dragEnd"
      :class="{'drag-over': dropFileDragOver && isUploadPermitted}"
      class="content-container"
    >
      <slot name="content"></slot>

      <v-snackbar
        :timeout="-1"
        :value="dropFileDragOver"
        content-class="text-center"
        color="primary"
        app
        shaped
      >
        <v-icon
          color="defaultIconColor"
          class="mx-2"
        >
          mdi-cloud-upload
        </v-icon>
        <span v-html="$t('drop_files_here', {folder: currentFolderName})"></span>
      </v-snackbar>
    </v-container>

    <DefaultModal
      :title="$t('file_upload')"
      :submit-button-text="'upload'"
      :saveButtonDisabled="modalSaveButtonDisabled"
      @submit="makeUpload"
      @modal-closed="resetUploadBehavior()"
      @dropped="onDrop"
      ref="uploadModal"
      eager
    >
      <template v-slot:content>
        <UploadField
          :rules="[v => (v && v.length > 0) || $t('form_rules.mandatory')]"
          :errorMessage="uploadErrorMessage"
          @clearInput="resetUploadBehavior()"
          @change.native="setUploadBehavior($event.target.files)"
          ref="uploadFileField"
        />
      </template>
    </DefaultModal>

    <DefaultModal
      :title="$t('new_folder')"
      :saveButtonDisabled="modalSaveButtonDisabled"
      @submit="createFolderAction"
      @modal-closed="resetUploadBehavior()"
      ref="folderModal"
    >
      <template v-slot:content>
        <v-text-field
          :label="$t('folder_name')"
          :rules="[
            v => !!v || $t('form_rules.mandatory'),
            v => /^([a-zA-Z0-9À-ž\s\._-]+)$/igu.test(v) || $t('form_rules.invalid_filename'),
            v => !/^(PRN|CON|AUX|CLOCK$|NUL|COM[0-9]|LPT[0-9])$/im.test(v) || $t('form_rules.invalid_filename'),
          ]"
          :error-messages="uploadErrorMessage"
          :autofocus="true"
          @clearInput="resetUploadBehavior()"
          @change.native="setCreateFolderBehavior($event.target.value)"
          name="name"
          outlined
          dense
        ></v-text-field>
      </template>
    </DefaultModal>

    <CreateGDocModal ref="gdocModal" @createdGDdoc="$emit('fileCreated')"/>

    <CreateSyntheticMediaModal ref="syntheticMediaModal" @createdSyntheticMedia="$emit('fileCreated')"/>
    <CreateSelfServiceTemplateModal ref="selfServiceTemplateModal" @createdSelfServiceTemplate="$emit('fileCreated')" />
    <DefaultModal
      :title="$t('move_confirm_title')"
      ref="dragModal"
      @submit="submit()"
      @modal-closed="resetDragModal()"
      :submit-button-text="submitTitle"
      :loading="modalLoading"
      :saveButtonDisabled="modalSaveButtonDisabled"
      :alert="modalAlert"
    >
      <template v-slot:content>
        <v-col class="text-body-1">
          <v-row>
            <p class="pre-line"><span class="font-weight-bold">From:</span> {{destination.from}}</p>
          </v-row>
          <v-row>
            <p><span class="font-weight-bold">To:</span> {{destination.to}}</p>
          </v-row>
          <v-row v-if="modalAlert" class="mt-4">
            <v-alert
              dense
              outlined
              :type="modalAlert.type"
            >
              {{modalAlert.message}}
            </v-alert>
          </v-row>
        </v-col>
      </template>
    </DefaultModal>
  </v-container>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .content-container {
    padding: 20px 10px 10px 10px;
  }

  .table-container {
    margin-top: 20px;
  }

  .row-container {
    margin-top: 10px;
    padding: 0 10px 0 10px;
  }

  .align-center {
    align-self: center;
  }

  .vertical-btn-group {
    flex-direction: column;
    width: 100%;
  }

  .vertical-btn-group > button {
    margin-top: 10px;
  }

  .drag-over {
    border: 2px dashed var(--v-primary-base);
    border-radius: 4px;
    background-color: var(--v-primary-lighten5);
  }

  .theme--light.v-btn.v-btn--has-bg {
    background-color: var(--v-appBackground-base);
  }
</style>

<script>

import { mapState, mapActions } from 'vuex'

import DefaultModal from '@/components/DefaultModal.vue'
import UploadButton from '@/components/content/default/Form/UploadButton.vue'
import UploadField from '@/components/content/default/Form/UploadField.vue'
import CreateGDocModal from '@/components/content/CreateGDocModal.vue'
import CreateSyntheticMediaModal from '@/components/content/CreateSyntheticMediaModal.vue'
import CreateSelfServiceTemplateModal from '@/components/content/CreateSelfServiceTemplateModal.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

import AppActions from '@/store/app/actions-types'
import ContentActions from '@/store/content/actions/actions-types'
import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: 'NavigationContainer',

  components:{
    DefaultModal,
    UploadButton,
    UploadField,
    CreateGDocModal,
    CreateSyntheticMediaModal,
    CreateSelfServiceTemplateModal,
    Breadcrumbs,
  },

  props: {
    breadcrumbsItems: {
      type: Array
    },

    actions: {
      type: Array
    },

    searchValue: {
      type: String
    },

    showUploadButton: {
      type: Boolean,
      default: false,
    },

    showSearchBar: {
      type: Boolean,
      default: true,
    },

    showToggleView: {
      type: Boolean,
      default: true,
    },

    submitTitle: {
      type: String
    },
  },

  watch: {
    '$route'() {
      this.handler()
    },

    breadcrumbsItems() {
      const repositoryId = this.breadcrumbsItems[1]?.to.params.parentId ?? null

      if (repositoryId) {
        this.currentRepositoryId = repositoryId
      }
    }
  },

  data() {
    return {
      availableActions: [],
      searchTerm: "",
      searchPlace: this.currentFolderId,
      currentRepositoryId: null,
      searchResult: false,
      modalSaveButtonDisabled: true,
      uploadErrorMessage: "",
      dropFileDragOver: false,
      destination: {},
      modalLoading: true,
      modalAlert: null,
      droppedNode: null,
    }
  },

  mounted() {
    this.handler()
  },

  computed: {
    ...mapState({
      contentPersonalPreferences: state => state.app.contentPersonalPreferences,
    }),

    isUploadPermitted() {
      return this.availableActions.some(a => a.action_key == 'upload')
    },

    canCreateEntries() {
      const createActions = ['upload', 'create_gdocs', 'create_synthetic_media']

      return this.availableActions.some(a => createActions.includes(a.action_key))
    },

    currentFolderId() {
      return this.$route.params.parentId ?? null
    },

    currentFolderName() {
      return this.breadcrumbsItems.at(-1).text ?? null
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_INFO_MESSAGE,
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.SET_CONTENT_PERSONAL_PREFERENCE,
    ]),

    ...mapActions('content/actions', [
      ContentActions.GET_NODE_ACTIONS,
      ContentActions.UPLOAD_FILE,
      ContentActions.CREATE_GDOC,
      ContentActions.CREATE_FOLDER,
    ]),

    ...mapActions('content/entry', [
      EntryActions.CHECK_ENTRY_NAME_EXISTS,
      EntryActions.CHECK_COPY_MOVE_POSSIBILITY
    ]),

    handler() {
      const parentId = this.$route.params.parentId

      this.searchPlace = parentId

      if (parentId) {
        this.fetchData(parentId)
      }
    },

    fetchData(parentId) {
      this[ContentActions.GET_NODE_ACTIONS](parentId)
        .then((result) => {
          this.availableActions = [...result]
          this.searchResult = false
        })
    },

    searchValueChanged() {
      let showBreadcrumb = this.searchPlace == this.currentFolderId
      this.$emit('search-value-changed', {term: this.searchTerm, baseNodeId: this.searchPlace}, showBreadcrumb)
      this.searchResult = true
    },

    clearSearch() {
      this.$emit('cleared-search', this.currentFolderId)
      this.searchResult = false
    },

    openUploadModal() {
      this.$refs.uploadModal.openModal()
    },

    openFolderModal() {
      this.$refs.folderModal.openModal()
    },

    openGdocModal(mimeType) {
      this.$refs.gdocModal.open(mimeType)
    },

    openSyntheticMediaModal() {
      this.$refs.syntheticMediaModal.open()
    },

    openSelfServiceTemplateModal() {
      this.$refs.selfServiceTemplateModal.open()
    },

    makeUpload() {
      const parentId = this.$route.params.parentId

      this.$refs.uploadFileField.getFiles().forEach(file => {
        this[ContentActions.UPLOAD_FILE]({parentId, file})
      })

      this[AppActions.OPEN_APP_INFO_MESSAGE](this.$t('upload_started'))

      this.$refs.uploadModal.closeModal()
    },

    createFolderAction(formData) {
      const folder_parent_id = this.$route.params.parentId
      const name = formData.get('name')

      this[ContentActions.CREATE_FOLDER]({folder_parent_id, name})
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('folder_created'))

          this.$refs.folderModal.closeModal()
        })
        .catch(() => {
          this.$refs.folderModal.submitting = false
        })
    },

    setUploadBehavior() {
      this.resetUploadBehavior()

      const files = this.$refs.uploadFileField.getFiles()

      const nodeId = this.$route.params.parentId
      const data = { paths: Array.from(files).map(file => file.name) }

      const duplicates = this.checkDuplicateFilesForUpload(files)

      this.checkEntryName(nodeId, data).then(existingNames => {
        const errorNames = [...existingNames, ...duplicates]

        if(errorNames.length == 0) {
          this.modalSaveButtonDisabled = false

          return
        }

        this.modalSaveButtonDisabled = true

        this.uploadErrorMessage = this.$t('upload_already_exists', {fileNames: errorNames.join(", ")})
      })
    },

    setCreateFolderBehavior(name) {
      this.resetUploadBehavior()

      const nodeId = this.$route.params.parentId
      const data = { paths: [name] }

      this.checkEntryName(nodeId, data).then(existingNames => {
        if(existingNames.length == 0) {
          this.modalSaveButtonDisabled = false

          return
        }

        this.modalSaveButtonDisabled = true

        this.uploadErrorMessage = this.$t('entry_already_exists', {entry: existingNames.join(", ")})
      })
    },

    checkEntryName(nodeId, data) {
      return this[EntryActions.CHECK_ENTRY_NAME_EXISTS]({nodeId, data})
        .then(res => {
          if(!res.already_exists.length) {
            return []
          }

          return res.already_exists
        })
        .catch(err => console.error(err))
    },

    checkDuplicateFilesForUpload(files) {
      const fileNames = files.map((item) => item.name)

      return fileNames.filter((item, index) => fileNames.indexOf(item) !== index)
    },

    resetUploadBehavior() {
      this.uploadErrorMessage = ""
      this.modalSaveButtonDisabled = true
    },

    viewModeChanged(mode) {
      this[AppActions.SET_CONTENT_PERSONAL_PREFERENCE]({
        key: 'navigationViewMode',
        value: mode
      })
    },

    submit() {
      const nodeId = this.droppedNode.node_id
      this.$refs.Navigation

      this.$refs.SelectedItemsBar.callActionMethod('moveAction', nodeId)
    },

    dragModal() {
      var sourceNodes = parent.draggedList
      this.droppedNode = parent.droppedNode
      this.destination.from = '\n' + sourceNodes.map(i => i.entry_fs_id).join(',\n')
      this.destination.to = this.droppedNode.node_parent === null ? `/${this.droppedNode.name}` : this.droppedNode.entry_fs_id;

      this.resetDragModal()
      this.$refs.dragModal.openModal()

      let nodesIds = [...new Set([sourceNodes.map(i => i.node_id)])]

      const data = {"destination_id": this.droppedNode.node_id, "action_key": 'move', 'nodes_ids': nodesIds}

      this[EntryActions.CHECK_COPY_MOVE_POSSIBILITY](data)
        .then(response => {
          this.modalLoading = false;

          response.forEach(res => {
            if (!res.node_name_already_exists) {
              this.modalSaveButtonDisabled = false;
              return
            }

            if (data.action_key === "copy") {
              this.modalSaveButtonDisabled = false;
              this.modalAlert = { type: "warning", message: this.$t('copy_confirm_alert') }
            }

            if (data.action_key === "move") {
              this.modalSaveButtonDisabled = true;
              this.modalAlert = { type: "error", message: this.$t('move_confirm_alert') }
            }
          });

        })
        .catch(() => this.loading = false)
    },

    resetDragModal() {
      this.modalLoading = true;
      this.modalSaveButtonDisabled = true;
      this.modalAlert = null;
    },

    dragOver(event) {
      var internal = parent.dragInternal
      if (!this.isUploadPermitted || internal) {
        this.dropFileDragOver = false
        return true
      }
      event.preventDefault()
      this.dropFileDragOver = true
      return true
    },

    dragEnter(event) {
      this.dragOver(event)
    },

    dragEnd() {
      parent.dragInternal = false
      this.dropFileDragOver = false
    },

    dragLeave() {
      this.dropFileDragOver = false
    },

    onDrop(event) {
      parent.dragInternal = false
      this.dropFileDragOver = false

      if (! this.isUploadPermitted) {
        return
      }

      const files = event.dataTransfer.files

      if (files.length == 0) {
        return
      }

      this.$refs.uploadFileField.addFiles(files)

      this.setUploadBehavior(files)

      this.openUploadModal()
    },

  }
}
</script>
